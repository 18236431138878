import { createApiClient } from '@/services/_auth_interceptors';

const config = require('../../oidc/index');

const apiClient = createApiClient(config.oidcSettings.spedtionConfigurationURL);

export const allegroAuthorizationService = {
  refreshAllegroToken(configurationSetId) {
    return apiClient.put(`refresh-token/${configurationSetId}`);
  },
  getAllegroAuthorizationToken(payload) {
    return apiClient.post('token', payload);
  },
  getAllegroDeliveryServices(urlParams) {
    return apiClient.get(`Webhook/Allegro/deliveryServices?${urlParams}`);
  },
};
